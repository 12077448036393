import { Component, OnInit, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
// import { NotifierService } from 'angular-notifier';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { AlertService, AuthenticationService, UserService } from '../services/index';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hide = true;
  loghide = true;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  // private readonly notifier: NotifierService;
  closeResult: string;
  registerForm: FormGroup;
  forgotPasswordForm: FormGroup;
  errorMessageRegister: string;
  errorMessageLogin: string;
  registrationSuccess: boolean = false;
  forgotPasswordSuccess: boolean = false;
  errorMessagePasswordExpired: string;
  errorMessageForgotPass: string;
  showPasswordRules: boolean = false;
  passwordHasUpperCase: boolean = false;
  passwordHasLowerCase: boolean = false;
  passwordHasNumber: boolean = false;
  passwordHasSpecialCharacter: boolean = false;
  passwordHasLength: boolean = false;
  passwordValid: boolean = false;
  registerFormSubmitted: boolean = false;
  //countryCode: number;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    // notifierService: NotifierService,
    private alertService: AlertService,
    private modalService: NgbModal
  ) { //this.notifier = notifierService; 
  }

  open(content) {
    this.modalService.dismissAll();
    this.modalService.open(content, { centered: true, size: 'lg', windowClass: 'modal-holder' });
  }

  loginAfterRegistration() {
    this.modalService.dismissAll();
    this.modalService.open("#loginScreen", { centered: true, size: 'lg', windowClass: 'modal-holder' })
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      // username: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
      username: new FormControl('', [Validators.required, Validators.email]),
      password: ['', Validators.required]
    });
    this.registerForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      personName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      // emailId: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
      emailId: new FormControl('', [Validators.required, Validators.email]),
      password: ['', [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")]],
    });
    this.forgotPasswordForm = this.formBuilder.group({
      emailId: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
    });
    // this.forgotPasswordForm1 = this.formBuilder.group({
      //     emailId : ['', [Validators.required, Validators.email]],
      //     password: ['', Validators.required],
      //     confirmPassword: ['', Validators.required]
      // }); 
      // reset login status
      this.authenticationService.logout();
      
      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';
    }
    
    get f() { return this.loginForm.controls; }
    get g() { return this.registerForm.controls; }
    get fp() { return this.forgotPasswordForm.controls; }
    
    onSubmit() {
     
      this.submitted = true;
      
      // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.userService.setUserID(data.uuid);
          if ((data.companyDetails !== undefined && data.companyDetails !== 'Not Found') || (data.financeDetails !== undefined && data.financeDetails !== 'Not Found') || (data.serviceDetails !== undefined && data.serviceDetails !== 'Not Found')
            || (data.diversityDetails !== undefined && data.diversityDetails !== 'Not Found') || (data.programDetails !== undefined && data.programDetails !== 'Not Found') || (data.referDetails !== undefined && data.referDetails !== 'Not Found')) {
            this.userService.setCompanyDetails(data.companyDetails);
            this.userService.setFinanceDetails(data.financeDetails);
            this.userService.setServiceDetails(data.serviceDetails);
            this.userService.setDiversityDetails(data.diversityDetails);
            this.userService.setProgramDetails(data.programDetails);
            this.userService.setRefferDetails(data.referDetails);
            this.userService.setRegisterDetails(data.register);
          }
          else if (data.message == "ChangeForcefully") {
            this.modalService.dismissAll();
            this.router.navigate(['/setPassword/' + data.token]);
            return;
          }
          else if (data.message == "expired") {
            this.errorMessagePasswordExpired = "Password expired.. Kindly check you email address for new password!!!";
            return;
          } else {
            this.alertService.error("Not Found");
          }
          this.modalService.dismissAll();
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.errorMessageLogin = `${error}`
          //this.notifier.notify( 'error', 'Invalid Username / Password, Please try Logging in again' );
          //this.loading = false;
        });

  }

  // onCountryChange(e){
  //   // console.log(e);
  //   this.countryCode = e.dialCode;
  // }

  getNumber(e){
    console.log("getNumber : "+e);
    this.registerForm.value.phoneNumber=e;
  
  }
  register() {
    this.submitted = true;
    
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    if(this.registerForm.value.role !== 'A'){   
    this.registerForm.value.supplierStatus = true;
    }
    
    this.registerFormSubmitted = true;
    
    this.loading = true;
    this.userService.register(this.registerForm.value)
      .pipe(first())
      .subscribe(

        data => {

          this.registrationSuccess = true;
          return;
          //this.alertService.success('Registration successful', true);
          //this.modalService.dismissAll();
          //this.router.navigate(['/login']);
        },
        error => {
          this.errorMessageRegister = error;
          //this.alertService.error(error);
          //this.loading = false;
        });
  }

  forgotPassword() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.loading = true;
    this.userService.forgotPassword(this.forgotPasswordForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.forgotPasswordSuccess = true;
          return;
        },
        error => {
          this.errorMessageForgotPass = error;
        });
  }

  // resetPassword(){
  //   this.submitted = true;

  //   // stop here if form is invalid
  //   if (this.forgotPasswordForm.invalid) {
  //       return;
  //   }else{
  //     if(this.forgotPasswordForm.value.password !== this.forgotPasswordForm.value.confirmPassword ){
  //       this.alertService.error('Passwords do not match');
  //       return false;
  //     }
  //   }

  //   this.loading = true;
  //     this.userService.resetPassword(this.forgotPasswordForm.value)
  //         .pipe(first())
  //         .subscribe(
  //             data => {
  //                 this.alertService.success('Passsword Reset Successfully', true);
  //                 this.modalService.dismissAll();
  //                 this.router.navigate(['/login']);
  //             },
  //             error => {
  //                 this.alertService.error(error);
  //                 this.loading = false;
  //             });
  // }


  validatePassword() {
    if (this.registerForm.value && this.registerForm.value.password) {
      this.passwordHasUpperCase = this.registerForm.value.password.match(/[A-Z]/) && this.registerForm.value.password.match(/[A-Z]/).index >= 0 ? true : false;
      this.passwordHasLowerCase = this.registerForm.value.password.match(/[a-z]/) && this.registerForm.value.password.match(/[a-z]/).index >= 0 ? true : false;
      this.passwordHasNumber = this.registerForm.value.password.match(/[0-9]/) && this.registerForm.value.password.match(/[0-9]/).index >= 0 ? true : false;
      this.passwordHasSpecialCharacter = this.registerForm.value.password.match(/[!@#$%^&*()_+~]/) && this.registerForm.value.password.match(/[!@#$%^&*()_+~]/).index ? true : false;
      this.passwordHasLength = this.registerForm.value.password.length >= 8 ? true : false;
      if (this.passwordHasUpperCase && this.passwordHasLowerCase && this.passwordHasNumber
        && this.passwordHasSpecialCharacter && this.passwordHasLength) {
        this.passwordValid = true;
      }
      else {
        this.passwordValid = false;
      }
    }
    else {
      this.passwordHasUpperCase = false;
      this.passwordHasLowerCase = false;
      this.passwordHasNumber = false;
      this.passwordHasSpecialCharacter = false;
      this.passwordHasLength = false;
      this.passwordValid = false;
    }

  }
  hasError(event: any): void {
    if (!event && this.registerForm.value.formControlPhone !== '') {
      this.registerForm.get('phoneNumber').setErrors(['invalid_cell_phone', true]);
    }
  }

}




