import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  MatButtonModule, MatTooltipModule, MatCardModule, MatDialogModule, MatInputModule, MatTableModule,
  MatToolbarModule, MatStepperModule, MatGridListModule, MatTabsModule, MatMenuModule, MatIconModule, MatProgressSpinnerModule, MatCheckboxModule,
  MatOptionModule, MatSelectModule, MatAutocompleteModule, MatDatepickerModule, MatNativeDateModule, MatPaginatorModule, MatRadioModule, MatSliderModule,
  MatSlideToggleModule,
} from '@angular/material';
@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatOptionModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatInputModule,
    MatStepperModule,
    MatTabsModule,
    MatDialogModule,
    MatGridListModule,
    MatTableModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSliderModule,
    MatSlideToggleModule,
  ],
  exports: [
    CommonModule,
    MatToolbarModule,
    MatOptionModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatTabsModule,
    MatSelectModule,
    MatInputModule,
    MatStepperModule,
    MatGridListModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSliderModule,
    MatSlideToggleModule,
  ],
})
export class CustomMaterialModule { }
