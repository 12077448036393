import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { Response, URLSearchParams } from '@angular/http'
import { User } from '../model/user';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';


let userID;
let registerDetails;
let companyExsistingDetails;
let financeExsistingDetails;
let serviceExsistingDetails;
let diversityExsistingDetails;
let programExsistingDetails;
let programcoiExsistingDetails;
let refferExsistingDetails;
let userData;
let contactExsistingDetails;

@Injectable()
export class UserService {
    routePageValue;
    serviceUrl = environment.serviceUrl;

    percentComplete: number = 0;
    profileComplete: boolean = false;
    isEdit: boolean = false;


    constructor(private http: HttpClient) { }


    setUserID(uuid) {
        //userID = uuid;
        sessionStorage.setItem('userID', uuid);
    }

    setSupplierStatus(status) {
        return this.http.post(this.serviceUrl + `supplierStatus`, status);
    }

    getUserID() {
        userID = sessionStorage.getItem('userID');
        return userID;
    }

    getUserDetails() {
        userData = JSON.parse(sessionStorage.getItem('currentUser'));
        let personName = userData.register.personName.trim();
        userData.firstName = personName.replace(/ .*/, '');
        userData.lastName = personName.match(/\w+$/)[0];
        userData.personName = personName;
        userData.companyName = userData.register.companyName;
        userData.cont_email = userData.register.emailId;
        userData.emailId = userData.register.emailId;
        this.percentComplete = this.getCompletionPercentage(userData, 0);
        userData.supplierProfileChk = userData.register.supplierStatus;
        const objectAsString = JSON.stringify(userData);
        //console.log('JJ' + objectAsString); 
        return userData;
    }
    // setRouteName(routePage) {
    //     this.routePageValue = routePage;
    // }

    getCompletionPercentage(userData, i) {
        let percentComplete: number = 0;
        if (this.routePageValue !== 'saveExit') {
            if (i == 1) {
                if (userData && userData.companyDetails && userData.companyDetails != "Not Found") {
                    percentComplete += 50;

                }
            } else if (i == 2) {
                if (userData && userData.financeDetails && userData.financeDetails != "Not Found") {
                    percentComplete += 25;

                }
            } else if (i == 3) {
                if (userData && userData.diversityDetails && userData.diversityDetails != "Not Found" && userData.diversityDetails.diversities.length != 0) {
                    percentComplete += 25;

                }
            } else {
                // if (userData && userData.companyDetails && userData.companyDetails != "Not Found") {
                //     percentComplete += 50;
                if (userData && userData.companyDetails && userData.companyDetails != "Not Found") {
                    percentComplete += 20;
                    if (userData.companyDetails.usStates.length > 0 && userData.companyDetails.industries.length > 0) {
                        percentComplete += 20;
                    }
                    if (userData.companyDetails.description !== "" || userData.companyDetails.yearEst !== "" || userData.companyDetails.businessOwner !== "" || userData.companyDetails.businessStruct) {
                        percentComplete += 10;
                    }
                }



                if (userData && userData.financeDetails && userData.financeDetails != "Not Found") {
                    percentComplete += 25;
                }
                // if (userData && userData.diversityDetails && userData.diversityDetails != "Not Found" && userData.diversityDetails.diversities.length != 0) {
                //     percentComplete += 25;
                // }
                percentComplete += 25;

            }
            return percentComplete;
        } else {
            if (i == 1) {
                if (userData && userData.companyDetails && userData.companyDetails != "Not Found") {
                    percentComplete += 20;
                    if (userData.companyDetails.usStates.length > 0 && userData.companyDetails.industries.length > 0) {
                        percentComplete += 20;
                    }
                    if (userData.companyDetails.description !== "" || userData.companyDetails.yearEst !== "" || userData.companyDetails.businessOwner !== "" || userData.companyDetails.businessStruct) {
                        percentComplete += 10;
                    }

                }

            }

            else if (i == 2) {
                if (userData && userData.financeDetails && userData.financeDetails != "Not Found") { percentComplete += 25; }
            }
            else if (i == 3) {
                if (userData && userData.diversityDetails && userData.diversityDetails != "Not Found" && userData.diversityDetails.diversities.length != 0) { percentComplete += 25; }
            }
            else {
                if (userData && userData.companyDetails && userData.companyDetails != "Not Found") {
                    percentComplete += 20;
                    if (userData.companyDetails.usStates.length > 0 && userData.companyDetails.industries.length > 0) {
                        percentComplete += 20;
                    }
                    if (userData.companyDetails.description !== "" || userData.companyDetails.yearEst !== "" || userData.companyDetails.businessOwner !== "" || userData.companyDetails.businessStruct) {
                        percentComplete += 10;
                    }

                }

                if (userData && userData.financeDetails && userData.financeDetails != "Not Found") { percentComplete += 25; }
                // if (userData && userData.diversityDetails && userData.diversityDetails != "Not Found" && userData.diversityDetails.diversities.length != 0) { percentComplete += 25; }
                percentComplete += 25;

            }
            return percentComplete;
        }

    }

    getProfileCompletionPercentage() {
        return this.percentComplete;
    }


    register(user: User) {
        return this.http.post(this.serviceUrl + `registration`, user);
    }

    sendMail(user: User) {
        return this.http.post(this.serviceUrl + `mailer`, user);
    }

    forgotPassword(user: User) {
        return this.http.post(this.serviceUrl + `forgotPassword`, user);
    }

    setPassword(user: User) {
        return this.http.post(this.serviceUrl + `setPassword`, user);
    }

    getTokenValidity(token: any) {
        return this.http.post(this.serviceUrl + `validity`, token);
    }

    supplier(user: User, i) {

        var userDetail = JSON.parse(sessionStorage.getItem('currentUser'));
        userDetail.companyDetails = user;
        this.percentComplete = this.getCompletionPercentage(userDetail, i);
        user["submission"] = this.percentComplete;
        sessionStorage.setItem('currentUser', JSON.stringify(userDetail));
        return this.http.post(this.serviceUrl + `supplier`, user);
    }

    finance(user: User, i) {
        var userDetail = JSON.parse(sessionStorage.getItem('currentUser'));
        userDetail.financeDetails = user;
        this.percentComplete = this.getCompletionPercentage(userDetail, i);
        user["submission"] = this.percentComplete;
        sessionStorage.setItem('currentUser', JSON.stringify(userDetail));
        console.log('Finance Info ' + user);
        return this.http.post(this.serviceUrl + `finance`, user);
    }

    service(user: User) {
        return this.http.post(this.serviceUrl + `service`, user);
    }

    diversity(user: User, i) {
        // console.log('diver', i);
        var userDetail = JSON.parse(sessionStorage.getItem('currentUser'));
        userDetail.diversityDetails = user;
        this.percentComplete = this.getCompletionPercentage(userDetail, i);
        user["submission"] = 25; //this.percentComplete;
        sessionStorage.setItem('currentUser', JSON.stringify(userDetail));
        console.log('Data ' + user);
        return this.http.post(this.serviceUrl + `diversity`, user);
    }

    programCoi(user: User) {
        var userDetail = JSON.parse(sessionStorage.getItem('currentUser'));
        userDetail.programcoiDetails = user;
        sessionStorage.setItem('currentUser', JSON.stringify(userDetail));
        this.percentComplete = this.getCompletionPercentage(userDetail, 0);
        return this.http.post(this.serviceUrl + `programCoi`, user);
    }

    program(user: User) {
        return this.http.post(this.serviceUrl + `program`, user);
    }

    addProgram(program_data) {
        return this.http.post(this.serviceUrl + `programCoi`, program_data);
    }

    reffer(user: User) {
        var userDetail = JSON.parse(sessionStorage.getItem('currentUser'));
        userDetail.referDetails = user;
        this.percentComplete = this.getCompletionPercentage(userDetail, 0);
        // user["percentage"] = this.percentComplete;
        sessionStorage.setItem('currentUser', JSON.stringify(userDetail));
        return this.http.post(this.serviceUrl + `reffer`, user);
    }

    submission(user: User) {
        var userDetail = JSON.parse(sessionStorage.getItem('currentUser'));
        userDetail.submission = user;
        sessionStorage.setItem('currentUser', JSON.stringify(userDetail));
        this.percentComplete = this.getCompletionPercentage(userDetail, 0);
        return this.http.post(this.serviceUrl + `submission`, user);
    }

    addSupplierProgram(suppProgramData) {
        var userDetail = JSON.parse(sessionStorage.getItem('currentUser'));
        userDetail.programcoiDetails = suppProgramData;
        sessionStorage.setItem('currentUser', JSON.stringify(userDetail));
        return this.http.post(this.serviceUrl + `supplier-program-form-submission`, suppProgramData);
    }

    uploadDocument(user: User) {
        return this.http.post(this.serviceUrl + `api/file/upload`, user);
    }

    getUserFileUrl(fileKey) {
        return this.serviceUrl + 'api/file/' + fileKey;
    }

    setCompanyDetails(companyDetails) {
        companyExsistingDetails = companyDetails;
    }

    getCompanyDetails() {
        return companyExsistingDetails;
    }

    setFinanceDetails(financeDetails) {
        financeExsistingDetails = financeDetails;
    }

    getFinanceDetails() {
        return financeExsistingDetails;
    }

    setServiceDetails(serviceDetails) {
        serviceExsistingDetails = serviceDetails;
    }

    getServiceDetails() {
        return serviceExsistingDetails;
    }
    setDiversityDetails(diversityDetails) {
        diversityExsistingDetails = diversityDetails;
    }

    getDiversityDetails() {
        return diversityExsistingDetails;
    }
    setProgramDetails(programDetails) {
        programExsistingDetails = programDetails;
    }

    getProgramDetails() {
        return programExsistingDetails;
    }

    setProgramcoiDetails(programcoiDetails) {
        programcoiExsistingDetails = programcoiDetails;
    }

    getProgramcoiDetails() {
        return programcoiExsistingDetails;
    }

    setRefferDetails(referDetails) {
        refferExsistingDetails = referDetails;
    }

    getRefferDetails() {
        return refferExsistingDetails;
    }
    setRegisterDetails(register) {
        registerDetails = register;
    }

    getRegisterDetails() {
        return registerDetails;
    }

    setContactDetails(contacts) {
        contactExsistingDetails = contacts;
    }

    getContactDetails() {
        return contactExsistingDetails;
    }

    setPercentile(user) {
        return this.http.post(this.serviceUrl + `setPercentile`, user);
    }

    getSuppliersList(): Observable<any> {
        return this.http.get(this.serviceUrl + `supplierList`);
    }

    getSuppliersListCount(): Observable<any> {
        return this.http.get(this.serviceUrl + `supplierListCount`);
    }

    supplierDetailsById(id) {
        // return this.http.post(this.serviceUrl + `fullsupplierDetails`, param);
        let Url = this.serviceUrl + `fullsupplierDetails`;
        return this.http.post<any>(Url, { id: id })
            .pipe(map(data => {
                if (data) {
                    sessionStorage.setItem('currentUser1', JSON.stringify(data));
                }
                //   return JSON.parse(sessionStorage.getItem('currentUser1'));
                return data;
            }));
    }

    getSupplierById() {
        return JSON.parse(sessionStorage.getItem('currentUser1'));
    }

    updatetUserID(uuid) {
        //userID = uuid;
        sessionStorage.setItem('userID', uuid);
    }

    uploadInvoice(file) {
        return this.http.post(this.serviceUrl + `generateInvoice`, file);
    }

    harmanReportAutomation(files) {
        return this.http.post(this.serviceUrl + `harman-report`, files);
    }

    SupplierReport() {
        return this.http.get(this.serviceUrl + `supplierReport`);
    }

    disableSupplier(id) {
        let Url = this.serviceUrl + `disable-user`;
        return this.http.post<any>(Url, { id: id })
            .pipe(map(data => {
                // if (data) {
                //     sessionStorage.setItem('currentUser1', JSON.stringify(data));
                // }
                // //   return JSON.parse(sessionStorage.getItem('currentUser1'));
                return data;
            }));
    }

    registerSupplierUser(supplierEmp) {
        let url = `${this.serviceUrl}suppliers-users`;

        return this.http.post<any>(url, { supplierEmp: supplierEmp }).pipe(map(res => {
            return res;
        }))
    }

    getSupplierUser(userid) {
        let url = `${this.serviceUrl}suppliers-users`;
        // let p: URLSearchParams = new URLSearchParams();
        // p.set('id', userid);
        return this.http.get(url, { params: { id: userid } });
    }

    destorySuppliers(userid) {
        let url = `${this.serviceUrl}suppliers-users`;

        return this.http.delete(url, { params: { id: userid } }).pipe(map(res => {
            return res;
        }))
    }

    supplierAggrements(user: User) {
        let url = `${this.serviceUrl}suppliers-aggrement-info`;
        return this.http.post(url, user).pipe(map(res => {
            return res;
        }))
    }

    registerSupplierByAdmin(user: User) {
        let url = `${this.serviceUrl}register-supplier-by-admin`;
        return this.http.post(url, user).pipe(map(res => {
            return res;
        }))
    }

    deleteregisterSupplierByAdmin(data: any) {
        let url = `${this.serviceUrl}deletemanageuserlistbyid`;
        return this.http.post(url, data).pipe(map(res => {
            return res;
        }))
    }

    registerSupplierByAdminUser(user: User) {
        let url = `${this.serviceUrl}register-supplier-by-admin-user`;
        console.log(url);
        console.log(user);
        return this.http.post(url, user).pipe(map(res => {
            return res;
        }))
    }

    updateSupplierByAdminUser(user: User) {
        let url = `${this.serviceUrl}updatemanageuserlistbyid`;
        console.log(url);
        console.log(user);
        return this.http.put(url, user).pipe(map(res => {
            return res;
        }))
    }

    registerSupplierByAdminEmail(supplierRegister) {
        let url = `${this.serviceUrl}send-manual-reg-email`;
        console.log(url);
        console.log(supplierRegister);
        return this.http.post(url, supplierRegister).pipe(map(res => {
            return res;
        }))
        //   return this.http.get(url, { params: { email: email, isRegistered: isregisterd } });
    }


    fectchProgramDetailsBySupplierId(id) {
        // return this.http.post(this.serviceUrl + `fullsupplierDetails`, param);
        let Url = this.serviceUrl + `fullsupplierDetails`;
        return this.http.post<any>(Url, { id: id })
            .pipe(map(data => {

                //   return JSON.parse(sessionStorage.getItem('currentUser1'));
                return data;
            }));
    }

    addProgramsWithAggrement(program_data) {
        let url = this.serviceUrl + `programs-with-aggrement`;
        return this.http.post<any>(url, program_data)
            .pipe(map(data => {
                return data;
            }));
    }

    getProgramsWithAggrement() {
        let url = this.serviceUrl + `programs-with-aggrement`;
        return this.http.get<any>(url);
    }

    delProgram(id) {
        let Url = this.serviceUrl + `programs-with-aggrement`;
        return this.http.put<any>(Url, { id: id })
            .pipe(map(data => {
                return data;
            }));
    }
    getProgramDetailsWithSuppliers(id) {
        let url = `${this.serviceUrl}program-details-with-suppliers`;
        return this.http.get(url, { params: { id: id } });
    }

    fetchusStatesNCitiesList() {
        let url = `${this.serviceUrl}usstatesandcities`

        return this.http.get<any>(url);
    }

    supplierProgStatusSubmission(supplierData) {
        let url = this.serviceUrl + `supp-program-status-submission`;
        return this.http.post<any>(url, supplierData)
            .pipe(map(data => {
                return data;
            }));
    }
    getActivityTrack(userId) {
        let url = `${this.serviceUrl}supp-activty-track`;
        return this.http.get(url, { params: { userId: userId } });
    }

    downloadDocument(url, filename) {
        this.http.get(url, { responseType: "blob" }).toPromise().then(file => {
            saveAs(file, filename);
        }).catch(err => console.error("download error = ", err));
    }

    getSupplierProgramWithAgreement(data) {
        let url = `${this.serviceUrl}programs-with-aggrement`;
        return this.http.get(url, { params: { id: data.id, clientName: data.clientName } });
    }
    agreementUploadByAdmin(agreementData) {
        let url = this.serviceUrl + `aggrement-upload-by-admin`;
        return this.http.post<any>(url, agreementData)
            .pipe(map(data => {
                return data;
            }));
    }

    getRemittanceLogList(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `loginloglist`, data);
    }

    getSupplierUserList(name: any): Observable<any> {
        let data = { 'supplierUserId': name }
        return this.http.post(this.serviceUrl + `manageuserlist`, data);
    }

    getSupplierUserListManagerCount(name: any): Observable<any> {
        let data = { 'supplierUserId': name }
        return this.http.post(this.serviceUrl + `manageuserlistcount`, data);
    }

    getRemittanceActiveLogList(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `activeloginloglist`, data);
    }

    getDocumentHistory(userId: any, client_name: any, position: any): Observable<any> {
        let data = { 'userId': userId, 'client_name': client_name, position: position }
        return this.http.post(this.serviceUrl + `document-history-insurance`, data);
    }

    getSupplierdocsListByNameAdmin(name: any): Observable<any> {
        return this.http.get(this.serviceUrl + `supplierListDocumentListAdminPanel`);
    }

    getDocumentDiversityHistory(userId: any, position: any): Observable<any> {
        let data = { 'userId': userId, position: position }
        return this.http.post(this.serviceUrl + `document-history-diversity`, data);
    }

    getDocumentDiversityFinance(userId: any): Observable<any> {
        let data = { 'userId': userId }
        return this.http.post(this.serviceUrl + `document-history-finance`, data);
    }

    getDocumentDiversitydocVoidCheck(userId: any): Observable<any> {
        let data = { 'userId': userId }
        return this.http.post(this.serviceUrl + `document-history-finance-docVoidCheck`, data);
    }

    getDocumentDiversitydocDepositAgreement(userId: any): Observable<any> {
        let data = { 'userId': userId }
        return this.http.post(this.serviceUrl + `document-history-finance-docDepositAgreement`, data);
    }

    logoutService(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `logout`, data);
    }

    getSupplierdocsList(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `document-expire-count`, data);
    }

    getSupplierdocsListByName(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `document-expire-supplier`, data);
    }

    getSupplierdocsListAdminDashboard(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `document-expire-count-admin`, data);
    }

    getSupplierdocsListByNameAdminDashboard(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `document-expire-supplier-admin`, data);
    }

    getSupplierdocsListByNameAdminDashboardFilter(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `document-expire-supplier-admin-filter`, data);
    }

    getSupplierdocsListByNameAdminDashboardToday(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `document-expire-supplier-admin-today`, data);
    }

    getSupplierdocsListByNameAdminDashboardTodayUpdatedDocs(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `document-expire-supplier-admin-today-changed-doc`, data);
    }

    sentEmailAlertSupplier(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `document-expire-supplier-admin-sent-alert-email`, data);
    }

    getSupplierdocsListDiversity(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `document-expire-count-diversity`, data);
    }

    getSupplierdocsListByNameDiversity(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `document-expire-count-diversity-supplier`, data);
    }

    saveRemittanceActiveLogList(userName: any, role: any, actionOn: any): Observable<any> {
        let data = { 'userName': userName, 'role': role, 'actionOn': actionOn }
        return this.http.post(this.serviceUrl + `register-activity-logs`, data);
    }

    getRemittanceActivityList(name: any): Observable<any> {
        let data = { 'name': name }
        return this.http.post(this.serviceUrl + `activityloglist`, data);
    }
}
